import { Component, EventEmitter, OnInit, Output,Input } from '@angular/core';

@Component({
  selector: 'button-search',
  templateUrl: './button-search.component.html',
  styles: [
  ]
})
export class ButtonSearchComponent implements OnInit {
  @Input() disabled: boolean;
  @Output() eventClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  search() {
    this.eventClick.emit();
  }
}