import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss']
})
export class ButtonEditComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() btn_class: string = 'btn-secondary';
  constructor() { }

  ngOnInit(): void {
  }

  Edit() {
    this.eventClick.emit();
  }
}
