import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-custome',
  templateUrl: './button-custome.component.html',
  styles: [
  ]
})
export class ButtonCustomeComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() caption: string = "";
  @Input() icon_name: string = "";
  @Input() disable: boolean;
  @Input() btn_class: string = 'secondary';

  constructor() { }

  ngOnInit(): void {
  }

  Action() {
    this.eventClick.emit();
  }

}
