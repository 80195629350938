import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'button-reject',
  templateUrl: './button-reject.component.html',
  styleUrls: ['./button-reject.component.scss']
})
export class ButtonRejectComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() disable: boolean;

  constructor() {
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }
  reject() {
    this.eventClick.emit();
  }
}
