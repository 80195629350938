import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-previous',
  templateUrl: './button-previous.component.html',
  styles: [
  ]
})
export class ButtonPreviousComponent implements OnInit {
  @Input() disable: boolean;
  @Output() eventClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  Previous() {
     this.eventClick.emit();
  }
}