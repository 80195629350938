import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-save',
	templateUrl: './button-save.component.html',
	styleUrls: ['./button-save.component.scss'],
})
export class ButtonSaveComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;
	@Input() caption: string = '';

	constructor() {}

	ngOnInit(): void {}

	Save() {
		this.eventClick.emit();
	}
}
