import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, merge, catchError, tap } from 'rxjs/operators';
import { CognitoService } from '../cognito/cognito.service';
@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
	constructor(private tokenService: CognitoService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
  ): Observable<HttpEvent<any>> {
	
	if (req.url.includes('GetActiveEnvironment')) {
		return next.handle(req);
  	}

	if (req.url.includes('GetPropertyAppVersion')) {
		return next.handle(req);
  	}

	if (req.url.includes('GetLastReleases')) {
		return next.handle(req);
  	}

	if (req.url.includes('GetSitesByUsername')) {
		return next.handle(req);
  	}

	// if (req.url.includes('EmployeeHasPermissionToScreen')) {
	// 	return next.handle(req);
  	// }
	  
	if (req.url.includes('GetRoleByUser')) {
		return next.handle(req);
  	}

	if (req.url.includes('assets/i18n/')) {
		return next.handle(req);
	}

	const urlBase = req.headers.get('urlBase');
	let headersNew = new HttpHeaders();
	if (
		req.url.includes('applications-info') ||
		req.url.includes('translations')
	) {
		return next.handle(req);
	}
		req.headers.keys().forEach((key) => {
			if (key !== 'urlBase') {
				headersNew = headersNew.append(key, req.headers.get(key));
			}
		});

		return this.tokenService.getAccessToken().pipe(
			mergeMap((token: string) => {
				const authorizedRequest = req.clone({
					headers: headersNew.append(
						'Authorization',
						`Bearer ${token}`,
					),
				});
				
				const expiry = JSON.parse(atob(token.split('.')[1])).exp;
				let now = (Date.now() / 1000);
				if (now >= expiry) {
					this.tokenService.LogOut();
					return next.handle(authorizedRequest);
				} else {
					return next.handle(authorizedRequest);
				}
			}),
		);
	}
}
