import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'button-cancel',
  templateUrl: './button-cancel.component.html',
  styleUrls: ['./button-cancel.component.scss']
})
export class ButtonCancelComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() btn_class: string = 'btn-secondary';
  constructor() { }

  ngOnInit(): void {
  }

  Cancel() {
    this.eventClick.emit();
  }
}
