import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouteReuseStrategy } from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { AppTranslationLoaderService } from 'src/app/core/services/translation-loader.service';
import { LogService } from '../content/sections/websocketclient/notification.services';
import { NotificationService } from '../../core/services/notification/notification.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { AppsInfo } from '../../core/translate/models/app-info';
import { environment } from '../../../environments/environment';
import { TranslateApplicationService } from '../../core/translate/translate-application-service';
import { Application } from '../../core/translate/models/application';
import { CognitoService } from '../../core/services/cognito/cognito.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SitesService } from '../content/sections/services/sites-services';
import { RolesService } from '../content/sections/services/roles-service';
import { NavigationService } from 'src/app/core/components/navigation/navigation.service';
import { AppNavigationModel } from 'src/app/navigation/navigation.model';
import { IRoles } from '../content/sections/Interfaces/IRoles';
import { ISites } from '../content/sections/Interfaces/ISites';
import { IEnvironments } from '../content/sections/Interfaces/IEnvironments';
import { EnvironmentsService } from '../content/sections/services/environments-services';
import { PropertiesService } from '../content/sections/services/properties-services';
import { IProperties } from '../content/sections/Interfaces/IProperties';
import { IEmployeesInActiveDirectory } from '../content/sections/Interfaces/IEmployeesInActiveDirectory';
import { ActiveDirectoryService } from '../content/sections/services/activedirectory-services';

declare var jQuery: any;
@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})

export class AppToolbarComponent {
	userStatusOptions: any[];
	languages: Array<any> = [];
	selectedLanguage: any;
	showLoadingBar: boolean;
	horizontalNav: boolean;
	username: string;
	appName: string;
	appVersion: string;
	isAuthenticated: boolean;
	showSite: boolean;
	formGroup: FormGroup;
	listSites: Array<any> = [];
	isTestingEnvironment: boolean;

	constructor(
		private router: Router,
		private appConfig: AppConfigService,
		private translate: TranslateApplicationService,
		private cognitoService: CognitoService,
		private applications: ApplicationInfoService,
		private appNavigationService: NavigationService,
		private siteService: SitesService,
		private rolesService: RolesService,
		private formBuilder: FormBuilder,
		private environmentsService: EnvironmentsService,
		//private logService: LogService,
		private notificationService: NotificationService,
		private propertiesService: PropertiesService,
		private activeDirectoryService: ActiveDirectoryService
	) {

		this.isAuthenticated = false;
		this.username = '';
		this.formGroup = this.formBuilder.group({
			fksite: [null, Validators.required],
		});
		this.appName = window["applicationName"];
		this.appVersion = window["applicationVersion"];
		this.languages = applications.languages;

		if (this.languages.length == 0) {
			this.languages.push({
				id: 1,
				title: 'English',
				flag: 'en',
				applicationId: 0
			});

			this.languages.push({
				id: 1,
				title: 'Spanish',
				flag: 'es',
				applicationId: 0
			});

		}

		this.SelectLanguage();

		router.events.subscribe(
			(event) => {
				if (event instanceof NavigationStart) {
					this.showLoadingBar = true;
				}
				if (event instanceof NavigationEnd) {
					this.showLoadingBar = false;
				}
			});

		this.appConfig.onSettingsChanged.subscribe((settings) => {
			this.horizontalNav = settings.layout.navigation === 'top';
		});

		this.ChooseEnviroment();
	}

	ChooseEnviroment() {
		if (this.cognitoService.IsAuthenticated()) {
			this.cognitoService.GetUserInfo().subscribe(
				(result) => {
					this.username = result['email'];
					let isDev = window['configuration'].isDev;
					let userNT = '';
					if (isDev) {
						//DEV
						userNT = result['cognito:username'];
						userNT = userNT.slice(5);
					} else {
						// STG | PRD
						userNT = result['custom:samaccountname'];
					}

					localStorage.setItem('username', userNT);
					var email = result['email'];
					localStorage.setItem('email', email);

					this.GetActiveDirectoryData();
					this.GetEnvironment();
					this.GetSites();
				});
		}
	}

	GetRol() {
		this.rolesService.GetRoleByUser().subscribe(
			(result: TransactionResult<IRoles>) => {

				if (result) {
					localStorage.setItem('role', result['role']);
				}
			}
		);
	}

	GetSites() {
		this.listSites = [];
		this.showSite = false;
		let first = true;
		let fksite = window.localStorage.getItem('pksite');
		this.siteService.GetSitesByUsername().subscribe(
			(result: TransactionResult<ISites[]>) => {
				if (result.success) {
					if (result.data.length > 0) {
						result.data.forEach((obj) => {
							if (first && fksite == undefined) {
								localStorage.setItem(
									'pksite',
									obj.pksite.toString(),
								);
								this.formGroup.controls.fksite.setValue(obj.pksite);
								this.showSite = true;
							}
							if (obj.pksite.toString() == fksite) {
								this.formGroup.controls.fksite.setValue(obj.pksite);
								this.showSite = true;
							}
							first = false;
							this.listSites.push(obj);
						});
						this.GetRol();
						this.appNavigationService.setNavigationModel(
							new AppNavigationModel(),
						);
					} else {
						this.appNavigationService.setNavigationModel(
							new AppNavigationModel(),
						);
					}
				}
			},
			(error: any) => { this.ErrorAlert(error); },
		);
	}

	ErrorAlert(message: any) {
		this.notificationService.addAlert({
			icon: 'all_inbox',
			title: 'Error',
			message: message,
			shown: true,
		});
	}

	ChangeSite() {
		if (this.cognitoService.IsAuthenticated()) {
			localStorage.setItem(
				'pksite',
				this.formGroup.controls.fksite.value,
			);
			jQuery('.btnReload').trigger('click');
			/*La siguiente linea crea todo el menu */
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		}
	}

	GetActiveDirectoryData() {
		var arr: IEmployeesInActiveDirectory[] = [];
		this.activeDirectoryService.GetEmployeeByEmailNumberName(localStorage.getItem('username')).subscribe(
			(result: TransactionResult<IEmployeesInActiveDirectory[]>) => {
				if (result.success) {
					var jsonObj = JSON.parse(result.data.toString());
					arr = jsonObj.data;
					localStorage.setItem(
						'position',
						arr[0].position.toString(),
					);
					localStorage.setItem('building', arr[0].building.toString());
				}
			});
	}

	GetEnvironment() {
		this.environmentsService.GetActiveEnvironment().subscribe(
			(result: TransactionResult<IEnvironments[]>) => {
				if (result.success) {
					this.isTestingEnvironment = result.data[0].description == "Staging" || result.data[0].description == "Development";
				}
			}
		);
	}

	SelectLanguage(): void {
		let indexLang = 0;
		if (this.languages != undefined) {
			if (navigator.language.split('-').length > 0) {
				indexLang = this.languages.findIndex(lang => lang.flag === navigator.language.split('-')[0]);
			}
			else {
				indexLang = this.languages.findIndex(lang => lang.flag === navigator.language);
			}

			if (indexLang < 0) {
				this.selectedLanguage = this.languages[0];
			} else {
				this.selectedLanguage = this.languages[indexLang];
			}
			let username = window.localStorage.getItem('username');
			let fksite = window.localStorage.getItem('pksite');
			if (fksite != null && fksite != 'null') {
				this.SetLanguageBySite();
			}
			else {
				this.SetLanguageByDefaultProperty();
			}
			this.SetLanguage(this.selectedLanguage);
		}
	}

	SetLanguageBySite() {
		this.siteService.GetSitesByUsername().subscribe(
			(result: TransactionResult<ISites[]>) => {
				if (result.success) {
					this.languages.forEach(element => {
						if (element.title == result.data[0].language) {
							this.selectedLanguage = element;
						}
					});
					this.SetLanguage(this.selectedLanguage);
				}
			},
			(error: any) => {
				console.log(error);
			},
		);
	}

	SetLanguageByDefaultProperty() {
		this.propertiesService.GetPropertyByName("DEFAULT_LANGUAGE_FLAG").subscribe(
			(result: TransactionResult<IProperties[]>) => {
				if (result.success) {
					this.languages.forEach(element => {
						if (element.flag == result.data[0].propertyvalue) {
							this.selectedLanguage = element;
						}
					});
					this.SetLanguage(this.selectedLanguage);
				}
			},
			(error: any) => {
				console.log(error);
			},
		);
	}


	SetLanguage(lang) {
		// Set the selected language for toolbar
		this.selectedLanguage = lang;
		// selected language for translations
		this.translate.use(lang);
	}

	LogOut(): void {
		localStorage.removeItem('username');
		localStorage.removeItem('email');
		localStorage.removeItem('pksite');
		localStorage.removeItem('position');
		localStorage.removeItem('building');
		this.cognitoService.LogOut();
	}
}
