import { Component, OnInit, Input, forwardRef, Injector, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'jabil-input',
  templateUrl: './jabil-input.component.html',
  styleUrls: ['./jabil-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JabilInputComponent),
      multi: true
    }
  ]
})
export class JabilInputComponent implements OnInit, ControlValueAccessor {
  @Input() caption: string;
  @Input() nameControl: string;
  @Input() placeholder: string;
  @Input() type: string = "text";
  @Input() isReadOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 0;
  @Input() tooltip_message: string = "";
  @Input() tooltip_position: string = "top";
  value: any;
  isDisabled: boolean;
  fm = new FormControl();
  ngControl: NgControl;
  @ViewChild("input") input: ElementRef;

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.fm.valueChanges.subscribe(v => {
      this.onChange(v);
    });
  }

  onChange = (_: any) => { }
  onTouch = () => { }


  onInput(value: any) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);

    if (this.value) {
      let element = this.input.nativeElement as HTMLElement;
      element.classList.remove("customError");
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouch = fn; }
  setDisabledState(isDisabled: boolean): void { this.isDisabled = isDisabled; }
}