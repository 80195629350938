import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IEnvironments } from '../Interfaces/IEnvironments';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentsService extends RestCallService {
	public dataToSend: IEnvironments = {
		pkenvironment: 0,
		environment: '',
		description: '',
		normalemails: false,
    notnormalemails: '',
    emailslambdas: '',
    available: false,
    fksite: 0,
    username: ''
	};

	constructor(
		public httpClient: HttpClient,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(httpClient, 'ERapi', toastr, notificationService, translate);
	}

  public GetActiveEnvironment() {
    this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post<IEnvironments[]>(
      '/api/Environments/GetActiveEnvironment',
      this.dataToSend
		);
	}
}
