import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IRoles } from '../Interfaces/IRoles';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { IScreens } from '../Interfaces/IScreens';
import { IRolesScreens } from '../Interfaces/IRolesScreens';


@Injectable({
	providedIn: 'root',
})
export class RolesService extends RestCallService {
	public dataToSend: IRoles = {
		pkrole: 0,
		role: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		fkrecordstatus: 1,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
		recordstatusname: '',
		check: false,
		available: false,
		fksite: 0,
		editable: false,
		position: ''
	};
	public rolesScreens: IRolesScreens = {
		fkrole: 0,
		fkscreen: 0,
		fksite: 0,
		username: '',
		available: false,
		orders: [],
		screen: '',
	};
	public role: IRoles = {
		pkrole: 0,
		role: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		fkrecordstatus: 1,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
		recordstatusname: '',
		check: false,
		available: false,
		fksite: 0,
		editable: false,
		position: ''
	};
	i: number;

	resultCreate: any;

	constructor(
		public httpClient: HttpClient,
		private cognitoService: CognitoService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(httpClient, 'ERapi', toastr, notificationService, translate);
	}

	public GetRolesBySite() {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post<IRoles[]>(
			'/api/Roles/GetRolesBySite',
			this.dataToSend,
		);
	}

	public GetScreens(data: any) {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();
		this.dataToSend.pkrole = data.pkrole;
		this.dataToSend.fksite = Number(localStorage.getItem('pksite'));
		return this.post<IScreens[]>('/api/Roles/GetScreens', this.dataToSend);
	}

	public CreateRole(data: any) {

		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();

		this.dataToSend.role = data.txtrole;
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post<IRoles>('/api/Roles/CreateRole', this.dataToSend);
	}

	public UpdateRole(data: any) {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();
		this.dataToSend.available = data.available;
		this.dataToSend.pkrole = data.pkrole;
		this.dataToSend.role = data.txtrole;
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.put<IRoles>('/api/Roles/UpdateRole', this.dataToSend);
	}

	public AddRolesScreens(data: any, dataScreens: any) {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();

		this.rolesScreens.fkrole = data.pkrole;
		this.rolesScreens.orders = dataScreens.orders;
		this.rolesScreens.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post<IRolesScreens>(
			'/api/Roles/CreateRolesScreens',
			this.rolesScreens
		);
	}

	public ChangeRoleStatus(data: any) {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();

		this.dataToSend.pkrole = data.pkrole;
		this.dataToSend.available = data.available;
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post('/api/Roles/ChangeRoleStatus', this.dataToSend);
	}

	public GetScreensByUsername() {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		this.dataToSend.username = localStorage.getItem('username');
		this.dataToSend.position = localStorage.getItem('position');
		return this.post<IScreens[]>(
			'/api/Roles/GetScreensByUsername',
			this.dataToSend,
		);
	}

	public EmployeeHasPermissionToScreen(screen: string) {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();

		this.dataToSend.username = localStorage.getItem('username');
		this.rolesScreens.fksite = Number(window.localStorage.getItem('pksite'));
		this.rolesScreens.screen = screen;

		return this.post<IScreens[]>(
			'/api/Roles/EmployeeHasPermissionToScreen',
			this.rolesScreens,
		);
	}

	public GetRoleByUser() {
		if (!this.cognitoService.IsAuthenticated())
			this.cognitoService.LogOut();

		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		this.dataToSend.username = localStorage.getItem('username');

		return this.post<IRoles>('/api/Roles/GetRoleByUser', this.dataToSend);
	}
}
