import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'button-edit-warning',
  templateUrl: './button-edit-warning.component.html',
  styleUrls: ['./button-edit-warning.component.scss']
})
export class ButtonEditWarningComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() class: string = "btn btn-warning";

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  edit() {
    this.eventClick.emit();
  }
}
