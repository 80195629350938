import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'button-upload',
	templateUrl: './button-upload.component.html',
	styleUrls: ['./button-upload.component.scss'],
})
export class ButtonUploadComponent implements OnInit {
	@Input() caption: string;
	@Input() disabled: boolean;
	@Output() eventClick = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	Upload() {
		this.eventClick.emit();
	}
}