import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-assign-warning',
  templateUrl: './button-assign-warning.component.html',
  styleUrls: ['./button-assign-warning.component.scss']
})
export class ButtonAssignWarningComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  Assign() {
    this.eventClick.emit();
  }
}
