import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { TranslatePipe } from './translate-pipe';
import { NewlinePipe } from './NewLinesPipe';

@NgModule({
    declarations: [
    KeysPipe,
    TranslatePipe,
    NewlinePipe
    ],    
    exports: [
      KeysPipe,
      TranslatePipe,
      NewlinePipe
    ]
})

export class AppPipesModule { }
