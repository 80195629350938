import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-uploadfile',
	templateUrl: './button-uploadfile.component.html',
})
export class ButtonUploadFileComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
	@Input() disable: boolean;
	@Input() accept: string = '';

	constructor() {}

	ngOnInit(): void {}

	Upload() {
		this.eventClick.emit();
  }
  
  SelectFile(event: any) {
    this.change.emit(event);
  }
}
