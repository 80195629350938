import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

 

@Component({

  selector: 'button-close',

  templateUrl: './button-close.component.html',

  styleUrls: ['./button-close.component.scss']

})

export class ButtonCloseComponent implements OnInit {

  @Output() eventClick = new EventEmitter<any>();
  @Input() btn_class: string = 'btn-secondary';
  @Input() caption: string = "";

 

  constructor() { }

 

  ngOnInit(): void {

  }

 

  Close() {

    this.eventClick.emit();

  }

}