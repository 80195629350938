import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
	public model: any[];

	constructor() {
		this.model = [
			{
				id: 'applications',
				title: 'Applications',
				translate: 'Employees Recognition',
				type: 'group',
				icon: 'apps',
				children: [
					{
						id: 'Home',
						title: 'Home',
						translate: 'NAVBAR.Home',
						type: 'item',
						url: '/main/home',
						icon: 'home',
					},
					{
						id: 'recognition',
						title: 'employees-recognition',
						translate: 'NAVBAR.Employees_Recognition',
						type: 'item',
						url: '/main/recognition',
						icon: 'emoji_events',
					},
					{
						id: 'my-recognitions',
						title: 'my-recognitions',
						translate: 'NAVBAR.My_recognitions',
						type: 'item',
						url: '/main/my-recognitions',
						icon: 'auto_stories',
					},
					{
						id: 'my-sent-recognitions',
						title: 'my-sent-recognitions',
						translate: 'NAVBAR.MySentRecognitions_Nav',
						type: 'item',
						url: '/main/my-sent-recognitions',
						icon: 'email',
					},
					{
						id: 'dl-recognitions',
						title: 'dl-recognitions',
						translate: 'NAVBAR.DL_recognitions',
						type: 'item',
						url: '/main/dl-recognitions',
						icon: 'military_tech',
					},
					{
						id: 'Approvals',
						title: 'approvals',
						translate: 'NAVBAR.Approvals',
						type: 'item',
						url: '/main/approvals',
						icon: 'check_circle',
					},
					{
						id: 'Catalogs',
						title: 'Catalogs',
						translate: 'NAVBAR.Catalogs',
						type: 'collapse',
						icon: 'workspaces',
						children: [
							{
								id: 'change-agent-actions',
								title: 'change-agent-action',
								translate: 'NAVBAR.change_agent_action',
								type: 'item',
								url: '/main/change-agent-actions',
								icon: 'edit_note'
							},
							{
								id: 'Projects',
								title: 'Projects',
								translate: 'NAVBAR.Projects',
								type: 'item',
								url: '/main/projects',
								icon: 'group_work',
							},
							// {
							// 	id: 'Areas',
							// 	title: 'Areas',
							// 	translate: 'NAVBAR.Areas',
							// 	type: 'item',
							// 	url: '/main/areas',
							// 	icon: 'location_pin',
							// },
							{
								id: 'shifts',
								title: 'shifts',
								translate: 'NAVBAR.shifts',
								type: 'item',
								url: '/main/shifts',
								icon: 'event'
							},
							{
								id: 'positions',
								title: 'positions',
								translate: 'NAVBAR.positions',
								type: 'item',
								url: '/main/positions',
								icon: 'closed_caption_off'
							}
						],
					},
					// {
					// 	id: 'Sites & buildings',
					// 	title: 'Sites & buildings',
					// 	translate: 'NAVBAR.Sites_and_buildings',
					// 	type: 'collapse',
					// 	icon: 'apartment',
					// 	children: [
					// 		{
					// 			id: 'Sites',
					// 			title: 'Sites',
					// 			translate: 'SITES.sitetitle',
					// 			type: 'item',
					// 			url: '/main/catalogs/Sites',
					// 			icon: 'business',
					// 		},
					// 		{
					// 			id: 'Buildings',
					// 			title: 'Buildings',
					// 			translate: 'BUILDINGS.buildingstitle',
					// 			type: 'item',
					// 			url: '/main/catalogs/Buildings',
					// 			icon: 'factory',
					// 		},
					// 	],
					// },
					{
						id: 'Sites',
						title: 'Sites',
						translate: 'SITES.sitetitle',
						type: 'item',
						url: '/main/catalogs/Sites',
						icon: 'business',
					},
					{
						id: 'Employees & roles',
						title: 'Users & Roles',
						translate: 'NAVBAR.Users_and_roles',
						type: 'collapse',
						icon: 'people_alt',
						children: [
							{
								id: 'Users',
								title: 'Users',
								translate: 'EMPLOYEES.Employees',
								type: 'item',
								url: '/main/employees',
								icon: 'person',
							},
							{
								id: 'Roles',
								title: 'Roles',
								translate: 'ROLES.Roles',
								type: 'item',
								url: '/main/roles',
								icon: 'lock_person',
							},
						],
					},
					{
						id: 'Reports',
						title: 'Reports',
						translate: 'NAVBAR.Reports',
						type: 'item',
						url: '/main/reports',
						icon: 'pie_chart',
					},
					{
						id: 'About',
						title: 'About',
						translate: 'NAVBAR.About',
						type: 'collapse',
						icon: 'help',
						children: [
							{
								id: 'contact',
								title: 'Contact',
								translate: 'CONTACT.Contact_title',
								type: 'item',
								url: '/main/contact',
								icon: 'phone',
							},
							{
								id: 'Releases',
								title: 'Last release',
								translate: 'Last_release',
								type: 'item',
								url: '/main/lastrelease',
								icon: 'refresh',
							},
						],
					},
				],
			},
		];
	}
}

