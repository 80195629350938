import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-add',
	templateUrl: './button-add.component.html',
	styleUrls: ['./button-add.component.scss'],
})
export class ButtonAddComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
  @Input() caption: string = "";
  @Input() disable: boolean;
	constructor() {}

	ngOnInit(): void {}

	Add() {
		this.eventClick.emit();
	}
}
