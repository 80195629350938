import { Component, OnInit } from '@angular/core';
import { TransactionResult } from 'src/app/core/services/interfaces/transaction-result';
import { environment } from '../../../environments/environment';
import { IProperties } from '../content/sections/Interfaces/IProperties';
import { PropertiesService } from '../content/sections/services/properties-services';
import { EnvironmentsService } from '../content/sections/services/environments-services';
import { IEnvironments } from '../content/sections/Interfaces/IEnvironments';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version: string = "";
  ENVIRONMENT: string;
  public production: boolean;
  constructor(
    private propertyService: PropertiesService,
    private environmentsService: EnvironmentsService,
    public notificationService: NotificationService,
    public translate: TranslateApplicationService,
  ) {
    this.production = environment.production;
  }

  ngOnInit() {
    this.propertyService.GetPropertyAppVersion().subscribe(
      (result: TransactionResult<IProperties[]>) => {
        if (result.success) {
          this.version = result.data[0].propertyvalue;
        }
      },
      (error: any) => {
      }
    );

    this.environmentsService.GetActiveEnvironment().subscribe(
      (result: TransactionResult<IEnvironments[]>) => {
        if (result.success) {
          this.ENVIRONMENT = result.data[0].environment;
        }
      }
    );
  }

  Alert(title: string, message: string) {
		this.notificationService.addAlert({
			icon: 'all_inbox',
			title: title,
			message: this.translate.TranslateKey(message),
			shown: true,
		});
	}
}
