import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSaveComponent } from './button-save/button-save.component';
import { TranslatePipe } from '../pipes/translate-pipe';
import { TranslateService } from '@ngx-translate/core';
import { AppPipesModule } from '../pipes/pipes.module';
import { ButtonCancelComponent } from './button-cancel/button-cancel.component';
import { ButtonAddComponent } from './button-add/button-add.component';
import { ButtonEditComponent } from './button-edit/button-edit.component';
import { ButtonCloseComponent } from './button-close/button-close.component';
import { ButtonAssignComponent } from './button-assign/button-assign.component';
import { ButtonAssignWarningComponent } from './button-assign-warning/button-assign-warning.component';
import { JabilSelectComponent } from './jabil-select/jabil-select.component';
import { JabilInputComponent } from './jabil-input/jabil-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JabilCheckBoxComponent } from './jabil-check-box/jabil-check-box.component';
import { JabilTextAreaComponent } from './jabil-text-area/jabil-text-area.component';
import { FormsModule } from '@angular/forms';
import { ButtonExportComponent } from './button-export/button-export.component';
import { ButtonDisableComponent } from './button-disable/button-disable.component';
import { ButtonUploadComponent } from './button-upload/button-upload.component';
import { ButtonApproveComponent } from './button-approve/button-approve.component';
import { ButtonSearchComponent } from './button-search/button-search.component';
import { ButtonViewComponent } from './button-view/button-view.component';
import { ButtonNextComponent } from './button-next/button-next.component';
import { ButtonPreviousComponent } from './button-previous/button-previous.component';
import { ButtonCustomeComponent } from './button-custome/button-custome.component';
import { ButtonUploadFileComponent } from './button-uploadfile/button-uploadfile.component';
import { JabilMultiselectComponent } from './jabil-multi-select/jabil-multi-select';
import { ButtonEditWarningComponent } from './button-edit-warning/button-edit-warning.component';
import { NgPrimeModule } from '../modules/ngprime.module';
import { MaterialModule } from '../modules/material.module';
import { JabilCaptionComponent } from './jabil-caption/jabil-caption.component';
import { ButtonRejectComponent } from './button-reject/button-reject.component';

export function setupTranslateFactory(
	service: TranslateService): Function {
	return () => service.use('en');
}

@NgModule({
	declarations: [
		ButtonSaveComponent,
		ButtonCancelComponent,
		ButtonAddComponent,
		ButtonEditComponent,
		ButtonCloseComponent,
		ButtonAssignComponent,
		ButtonAssignWarningComponent,
		ButtonExportComponent,
		ButtonDisableComponent,
		JabilSelectComponent,
		JabilInputComponent,
		JabilCheckBoxComponent,
		JabilTextAreaComponent,
		JabilCaptionComponent,
		ButtonUploadComponent,
		ButtonApproveComponent,
		ButtonRejectComponent,
		ButtonSearchComponent,
		ButtonViewComponent,
		ButtonNextComponent,
		ButtonPreviousComponent,
		ButtonCustomeComponent,
		ButtonUploadFileComponent,
		JabilMultiselectComponent,
		ButtonEditWarningComponent
	],
	imports: [CommonModule, AppPipesModule, NgSelectModule, FormsModule, NgPrimeModule, MaterialModule],
	exports: [
		ButtonAddComponent,
		ButtonEditComponent,
		ButtonCloseComponent,
		ButtonAssignComponent,
		ButtonAssignWarningComponent,
		ButtonSaveComponent,
		ButtonCancelComponent,
		ButtonExportComponent,
		ButtonDisableComponent,
		JabilSelectComponent,
		JabilInputComponent,
		JabilCheckBoxComponent,
		JabilTextAreaComponent,
		JabilCaptionComponent,
		ButtonUploadComponent,
		ButtonApproveComponent,
		ButtonRejectComponent,
		ButtonSearchComponent,
		ButtonViewComponent,
		ButtonNextComponent,
		ButtonPreviousComponent,
		ButtonCustomeComponent,
		ButtonUploadFileComponent,
		JabilMultiselectComponent,
		ButtonEditWarningComponent
	],
	providers: [
		TranslateService,
		{
			provide: APP_INITIALIZER,
			useFactory: setupTranslateFactory,
			deps: [TranslateService],
			multi: true,
		},
	],
})
export class ControlModule { }
