import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IEmployeesInActiveDirectory } from '../Interfaces/IEmployeesInActiveDirectory';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';

@Injectable({
  providedIn: 'root'
})
export class ActiveDirectoryService extends RestCallService {

  public employee: IEmployeesInActiveDirectory = {
    value: "",
    workdayId: "",
    employeeNumber: "",
    userName: "",
    name: "",
    email: "",
    position: "",
    division: "",
    building: "",
    department: "",
    manager: "",
    distinguishedname: "",
    fksiteloggeduser: 0,
    costCenter: ""
  };

  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public notificationService: NotificationService,
    public translate: TranslateApplicationService
  ) {
    super(httpClient, 'ERapi', toastr, notificationService, translate);
  }


  public GetEmployeeByEmailNumberName(data: string) {
    this.employee.value = data;
    return this.post<IEmployeesInActiveDirectory[]>('/api/ActiveDirectory/GetEmployeeByEmailNumberName', this.employee);
  }

  public GetEmployeeByDistinguishedname(data: string) {
    this.employee.value = data;
    return this.post<IEmployeesInActiveDirectory[]>('/api/ActiveDirectory/GetEmployeeByDistinguishedname', this.employee);
  }
}
