import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-next',
  templateUrl: './button-next.component.html',
  styles: [
  ]
})
export class ButtonNextComponent implements OnInit {
  @Input() disable: boolean;
  @Output() eventClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  Next() {
    this.eventClick.emit();
  }
}
