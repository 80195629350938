import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-approve',
  templateUrl: './button-approve.component.html',
  styles: [
  ]
})
export class ButtonApproveComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() disable: boolean;

  constructor() {
    // This is intentional 
  }

  ngOnInit(): void {
    // This is intentional
  }

  Approve() {
    this.eventClick.emit();
  }

}
