import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'jabil-multi-select',
	templateUrl: 'jabil-multi-select.html'
})
export class JabilMultiselectComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;
	@Input() caption: string = "";

	constructor() {}

	ngOnInit(): void {}

}